import * as Comlink from "comlink";

import { IEmulatorWorker, IRenderWorker } from "./common/types"

var lastKey: number;

const canvas = <HTMLCanvasElement> document.getElementById('screen');
const context = canvas.getContext('2d');

const emulatorWorker = Comlink.wrap<IEmulatorWorker>(
  new Worker(new URL('workers/emulatorWorker.ts', import.meta.url), {type: "module"})
);
const renderWorker = Comlink.wrap<IRenderWorker>(
  new Worker(new URL('workers/renderWorker.ts', import.meta.url), {type: "module"})
);

const program = new Int16Array(0x8000);
const binary = canvas.innerHTML.split(/\s+/);
for(var i = 0; i < binary.length; i++) {
  program[i] = parseInt(binary[i], 2);
}

emulatorWorker.loadProgram(program).then(async () => {
  await emulatorWorker.start();
  requestRender();
});

canvas.addEventListener('keydown', function(e) {
  lastKey = e.keyCode;
  emulatorWorker.keydown(e.keyCode);
});

canvas.addEventListener('keyup', function(e) {
  if(lastKey == e.keyCode) {
    emulatorWorker.keyup();
  }
});

async function requestRender() {
  const screenBuffer = await emulatorWorker.getScreenBuffer();
  const imageData = await renderWorker.renderScreenBuffer(screenBuffer);
  context.putImageData(imageData, 0, 0);
  requestAnimationFrame(requestRender);
}
